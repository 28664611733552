import React from 'react';

const HeadingSubheading = (props) => {
    return (
        <div>
            <h1 className="mt-7 text-4xl md:text-5xl text-center font-light font-brandonGrotesque">{props.pageHeading}</h1>
            <p className="pt-7 font-brandonGrotesque text-center text-lg font-light font-brandonGrotesque px-4 md:px-36">{props.pageSubheading}</p>
        </div>
    );
};

export default HeadingSubheading;
