import React, { useEffect } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "./Components/footer/Footer";
import About from "./Components/pages/about/About";
import LandingPage from "./Components/pages/landing-page/LandingPage";
import NavbarOther from "./Components/navbar/NavbarOther";
import ContactMe from "./Components/pages/contact/ContactMe";
import Booking from "./Components/pages/booking/Booking";
import './App.css';
import FormSuccess from "./Components/pages/booking/FormSuccess";
import Weddings from "./Components/pages/portfolio/portfolio-works/Weddings";
import Portfolio from "./Components/pages/portfolio/Portfolio";
import FashionEditorial from "./Components/pages/portfolio/portfolio-works/FashionEditorial";
import Corporate from "./Components/pages/portfolio/portfolio-works/Corporate";
import RealEstate from "./Components/pages/portfolio/portfolio-works/RealEstate";
import ShadesOfAfricanism from "./Components/pages/portfolio/portfolio-works/ShadesOfAfricanism";
import TravelCulture from "./Components/pages/portfolio/portfolio-works/TravelCulture";
import Videography from "./Components/pages/videography/Videography";
import PackageCategories from "./Components/pages/packages/PackageCategories";
import BlogHero from "./Components/pages/blog/BlogHero";
import BlogArticle from "./Components/pages/blog/BlogArticle";
import BlogHome from "./Components/pages/blog/BlogHome";

// Google Tags
import TagManager from 'react-gtm-module';

function App() {

   useEffect(() => {
      const tagManagerArgs = {
          gtmId: 'G-VNZTS58WHW'
      };
      TagManager.initialize(tagManagerArgs);
  }, []);


   return (
       <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
             <div className="h-full w-full">
             <NavbarOther/>
                <Routes>
                   <Route exact path="/" element={<LandingPage/>}/>
                </Routes>
               
                <Routes>
                   <Route exact path="/about" element={<About/>}/>
                   <Route exact path="/book-me" element={<Booking/>}/>
                   <Route exact path="/contact-me" element={<ContactMe/>}/>
                   <Route exact path="/email-success" element={<FormSuccess/>}/>

                   {/* Portfolio */}
                   <Route exact path="/portfolio" element={<Portfolio />}/>
                   <Route exact path="/portfolio/weddings" element={<Weddings/>}/>
                   <Route exact path="/portfolio/corporate" element={<Corporate />}/>
                   <Route exact path="/portfolio/fashion-editorials" element={<FashionEditorial />}/>
                   <Route exact path="/portfolio/real-estate" element={<RealEstate />}/>
                   <Route exact path="/portfolio/travel-culture" element={<TravelCulture />}/>
                   <Route exact path="/portfolio/shades-of-africanism" element={<ShadesOfAfricanism />}/>

                   {/* Packages */}
                   <Route exact path="/packages" element={<PackageCategories />}/>
                   {/*Individual Packages*/}
                        {/* Removed */}
                        {/* <Route exact path="/packages/weddings" element={<WeddingPackages/>}/>
                        <Route exact path="/packages/birthday" element={<BirthdayPackages/>}/>
                        <Route exact path="/packages/fashion" element={<FashionPackages/>}/>
                        <Route exact path="/packages/corporates" element={<CorporatesPackages/>}/> */}

                   {/* Videography*/}
                   <Route exact path="/videography" element={<Videography />}/>
                  {/*   Other pages */}
                  
                  {/* blog page */}
                  <Route exact path="/blog" element={<BlogHero/>} />
                  <Route path="/blog/:id" element={<BlogArticle/>} />
                  <Route path="/blog-home" element={<BlogHome/>}/>
                  
                  {/* end blog page */}
                </Routes>

                <Footer/>
             </div>
          </BrowserRouter>
       </LocalizationProvider>
   );
}

export default App;
