import React from 'react';
import FooterGallery from "./FooterGallery";
import { navigationLinks } from "../../utils";
import { HiOutlineArrowUp } from "react-icons/hi";
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
   return (
       <div className="md:px-16">

          {/* SOCIAL LINKS*/}
          <div className="flex justify-center space-x-4 py-12">
             <a href="https://www.instagram.com/mannuelportraits/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size="30"/>
             </a>
             <a href="https://www.facebook.com/mannuelportraits" target="_blank" rel="noopener noreferrer">
                <FaFacebook size="30"/>
             </a>
             {/* TODO change links for twitter */}
             <a href="https://twitter.com/mannuelportraits" target="_blank" rel="noopener noreferrer">
                <FaTwitter size="30"/>
             </a>
             <a href="https://www.linkedin.com/in/emmanuel-mutisya-6531a921b/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size="30"/>
             </a>
             <a href="https://www.youtube.com/@shadesofafricanism4541" target="_blank" rel="noopener noreferrer">
                <FaYoutube size="30"/>
             </a>
             <a href="https://wa.me/254743253043" target="_blank" rel="noopener noreferrer">
                <IoLogoWhatsapp size="30"/>
             </a>
          </div>
          {/* END OF SOCIAL LINKS*/}

          <div className="bg-gray-300 py-6 px-6">
             <FooterGallery/>
          </div>

          <div className="pt-12 pb-8 md:pt-24">
             <div
                 className="flex flex-col items-center space-y-8 md:space-y-0 md:flex-row md:justify-between font-cprime font-semibold text-sm text-gray-700 tracking-widest1">
                {navigationLinks.map((item, index) => (
                    <div>
                       <a href={item.href} className="text-sm uppercase md:mr-7">{item.name}</a>
                    </div>
                ))}
             </div>
          </div>

          <hr className="mx-6 md:mx-0 h-[2px] bg-gray-300"/>

          <div className="py-8 flex flex-col items-center md:flex-row justify-between">
             <p className="text-center text-[1.1rem] md:text-left text-para font-medium leading-[26px] text-sm">© 2024
                <span className="font-brushSignature"> Mannuel Portraits</span>
             </p>

             <a href="#root" className="hover:cursor-pointer md:mx-auto mt-4 md:mt-0">
                <HiOutlineArrowUp size="30"/>
             </a>
             {/*<FontAwesomeIcon icon="fa-thin fa-arrow-up-long"/>*/}
          </div>
       </div>
   );
};

export default Footer;
