import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black; /* Set the border color to black */
`;

export default function BlogHero() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const apiUrl = 'https://mannuelportraits.up.railway.app/api/article-list/';

        axios.get(apiUrl)
            .then((response) => {
                setPosts(response.data);
                console.log(response.data)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    // Assuming `post.datetime` is a valid date string (e.g., "2023-10-26T12:34:56Z")


    return (
        <div className="bg-white py-24 sm:py-32">
            <Helmet>
                <title>Blogs - MannuelPortraits</title>
                <meta name="description" content="Photography Articles and News by MannuelPortraits"/>
                <meta name="keywords" content="Best Photographer in Kenya"/>
                <link rel="canonical" href={canonicalURL + "/blog"}/>
            </Helmet>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">MannuelPortraits Blogs</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Explore . Create . Inspire
                    </p>
                </div>
                <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {loading && (
                        <div className="col-span-3 flex justify-center items-center">
                            <ClipLoader color="black" css={override} size={30} />
                        </div>
                    )}
                    {!loading && posts.map((post) => (
                        <Link to={`/blog/${post.id}`} key={post.id}>
                            <article
                                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                            >
                                <img src={post.thumbnail} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                    <time dateTime={post.datetime} className="mr-8">
                                        {post.date} ago
                                    </time>
                                    <div className="-ml-4 flex items-center gap-x-4">
                                        <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="flex gap-x-2.5">
                                            <p className="flex-none">{post.author.username}</p>
                                            {post.author.name}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    <a href={post.href}>
                                        <span className="absolute inset-0" />
                                        {post.title}
                                    </a>
                                </h3>
                            </article>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}
