import React from 'react';
import wedding_prep_bw from "../../../img/wedding-prep/wedding_prep_bw.jpg";
import Button from "../../utils/Button";

const MyComponent = () => {
   return (
       <div className="w-full mb-10 md:mb-20">
          <div className="flex flex-col-reverse md:flex-row bg-grayLightGreen">
             <div className="md:w-1/2">
                <div className="w-full h-full flex justify-center items-center mb-10 md:mb-0">
                   <div className="flex flex-col items-center">
                      <h3 className="font-cormorantGaramond text-5xl md:text-7xl font-light pt-14 md:pt-0 pb-4">
                         Plan Your Shoot
                      </h3>
                      <p className="font-cprime font-light uppercase md:text-xl pb-9 md:pb-12">Want to
                         book a session?</p>
                      <a href="/book-me">
                         <Button className="font-cormorantGaramond uppercase text-2xl" text={"Book a session"}></Button>
                      </a>
                   </div>
                </div>
             </div>
             <div className="md:w-1/2">
                <img className="w-full h-[30vh] md:h-[500px] object-cover" src={wedding_prep_bw} alt="wedding preparation"/>
             </div>
          </div>
       </div>
   );
};

export default MyComponent;
