import React from 'react';
import MyStory from "./MyStory";
import {Helmet} from "react-helmet-async";
import canonicalURL from "../../utils/canonicalURL";


const About = () => {
   return (
       <div>
           <Helmet>
                <title>About - MannuelPortraits</title>
                <meta name="description" content="My Story - How I became the best Photographer"/>
                <meta name="keywords" content="Photography, Photographer, Photos, Portfolio, My Story"/>
               <link rel="canonical" href={canonicalURL + "/about"}/>
           </Helmet>
          <MyStory/>
          <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
          <div className="elfsight-app-aa9446f6-903f-4644-b532-3670a9ae0424 mt-12" data-elfsight-app-lazy></div>
       </div>
   );
};

export default About;
