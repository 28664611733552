import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VideoPlayer from "./videoplayer/VideoPlayer";
import HeadingSubheading from "../../utils/HeadingSubheading";
import { ClipLoader } from 'react-spinners';
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async"; // Importing a spinner component

const Videography = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading status

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get('https://mannuelportraits.up.railway.app/api/film-list/');
                setVideos(response.data);
            } catch (error) {
                console.error("Error fetching the videos:", error);
            } finally {
                setLoading(false); // Set loading to false after the fetch is complete
            }
        };

        fetchVideos();
    }, []);

    return (
        <>

            <Helmet>
                <title>Films - MannuelPortraits</title>
                <meta name="description" content="Best Film projects by best videographer in Kenya"/>
                <meta name="keywords" content="Best Photographer in Kenya"/>
                <link rel="canonical" href={canonicalURL + "/videography"}/>
            </Helmet>

            <div className="mb-14">
                <HeadingSubheading pageHeading="Films"/>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-5">
                    {videos.map((video) => (
                        <div className="mb-8" key={video.id}>
                            <div className="w-full h-0 pb-[56.25%] relative">
                                <VideoPlayer videoId={video.id} previewImage={video.image} videoUrl={video.video_url}/>
                            </div>
                            <p className="mt-2 text-center text-lg font-semibold">{video.title}</p>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Videography;
