import React, { Fragment } from 'react';

const Button = ({ text, classes }) => {
   return (
       <Fragment>
          <button className={"hover:bg-medium-harrows-gate transition-colors duration-200 uppercase font btnReadMore font-vollkorn tracking-wide text-white my-6 py-3 px-10 " + classes}>
             {text}
          </button>
       </Fragment>
   );
};

export default Button;
