import React from 'react';
import PageLinks from "./PageLinks";
import ContactMeIntro from "./ContactMeIntro";
import BlogHome from "./../blog/BlogHome";
import LandingCarousel from './LandingCarousel';


const LandingPage = () => {
   return (
       <>
         <LandingCarousel/>
          <BlogHome/>
          <PageLinks />
          <ContactMeIntro />
       </>
   );
};

export default LandingPage;
