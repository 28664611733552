import React, { useEffect, useState } from 'react';
import PortfolioItem from "../PortfolioItem";

const FashionEditorial = () => {
    const [images, setImages] = useState([]);
    const subheading = "This section captures the essence of fashion and beauty, conveying a sense of style, elegance, and sophistication.";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://mannuelportraitsapi-production.up.railway.app/api/photo-list");
                const data = await response.json();
                const filteredImages = data
                    .filter(item => item.category && item.category.name === "Fashion & Editorial")
                    .map(item => ({ id: item.id, imgSrc: item.image }));
                setImages(filteredImages);
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs only once on component mount

    return (
        <>
            <PortfolioItem title="Fashion & Editorial" images={images} subheading={subheading}/>
        </>
    );
};

export default FashionEditorial;
