import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import Button from "../../utils/Button";
import { Box } from "@mui/material";
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async";

const Booking = () => {
   const navigate = useNavigate();
   const [date, setDate] = React.useState(dayjs('YYYY-MM-DD'));
   const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      message: '',
      email: '',
      phoneNumber: '',
   });

   const handleSubmit = (event) => {
      event.preventDefault();
      console.log(formData);
      console.log(dayjs(date).format('YYYY-MM-DD'));
      console.log(event.target)

      // SEND EMAIL USING EMAILJS
      emailjs.sendForm('service_zclpiek',
          'template_0yo2iia',
          event.target,
          'IaCWYwNG3cj2t-Vyw')
          .then(res => {
             if (res.status === 200) {

                navigate('/email-success');
             }
          }).catch(err => {
         console.log(err);
      });
   };

   const { firstName, lastName, message, email, phoneNumber } = formData;

   const onInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
   };

   const DateInput = function DateInput(props) {
      const { inputProps, InputProps, ownerState, inputRef, error, ...other } = props;

      return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} ref={InputProps?.ref}>
             <input ref={inputRef} {...inputProps} {...other} name="date" />
             {InputProps?.endAdornment}
          </Box>
      );
   };

   return (
       <div className="px-4 md:px-16 py-8 md:h-[160%]">
          <Helmet>
             <title>Book Me - MannuelPortraits</title>
             <meta name="description" content="Book your photography or videography Session today"/>
             <meta name="keywords" content="Book a session, Book Me, Book Mannuel Portraits, Book Mannuel Portraits, Available photographer in Kenya Nairobi Diani, Book drone photographer, Drone photography"/>
             <link rel="canonical" href={canonicalURL + "/book-me"}/>
          </Helmet>
          <div className="flex flex-col md:flex-row justify-center h-full md:mx-16 md:p-24">
             <div className="h-[20vh] md:h-auto md:w-1/2">
                <div className="booking-bg-img h-full w-full bg-center bg-no-repeat bg-cover">
                </div>
             </div>
             <div className="md:w-1/2">
                <div className="md:pl-12 py-10">
                   <div>
                      <h2 className="text-7xl font-medium font-adineKirnberg">Book a session</h2>
                   </div>
                   <div className="pb-8">
                      <p className="text-sm font-worksans text-gray-600">Thank you so much for making it this far.
                         Please fill out the contact form below with as much detail as you can and I will respond shortly. MannuelPortraits </p>
                   </div>

                   <form onSubmit={handleSubmit} className="space-y-6 xl:space-y-7 font-cprime">
                      <div className="flex">
                         <div className="mr-7">
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                               First Name
                            </label>
                            <input
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                value={firstName}
                                onChange={onInputChange}
                                type="text"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                            />
                         </div>
                         <div>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                               Last Name
                            </label>
                            <input
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={onInputChange}
                                type="text"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                            />
                         </div>
                      </div>
                      <div>
                         <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email Address
                         </label>
                         <input
                             id="email"
                             name="email"
                             placeholder="Email Address"
                             value={email}
                             onChange={onInputChange}
                             type="email"
                             required
                             className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                         />
                      </div>
                      <div>
                         <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            Phone Number
                         </label>
                         <input
                             id="phoneNumber"
                             name="phoneNumber"
                             placeholder="Phone Number"
                             value={phoneNumber}
                             onChange={onInputChange}
                             type="text"
                             required
                             className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                         />
                      </div>
                      <div>
                         <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Date
                         </label>
                         <DatePicker
                             className="appearance-none block md:w-3/4 xl:w-1/2 px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                             slots={{
                                textField: DateInput,
                             }}
                             value={date}
                             onChange={(newDate) => setDate(newDate)}
                         />
                      </div>
                      <div>
                         <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                            Message
                         </label>
                         <textarea
                             id="message"
                             name="message"
                             placeholder="Enter your message here..."
                             value={message}
                             onChange={onInputChange}
                             required
                             rows="6"
                             className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-harrows-gate focus:border-harrows-gate sm:text-sm"
                         />
                      </div>
                      <div>
                         <Button
                             text="Submit"
                             type="submit"
                             classes="shadow-sm font-medium focus:outline-none hover:bg-light-harrows-gate focus:ring-2 focus:ring-offset-2 focus:ring-light-harrows-gate"
                             // type="submit"
                             // value="Submit"
                         />
                      </div>
                   </form>
                </div>
             </div>
          </div>
          <hr className="md:mx-6 md:mx-28 border-1 h-[2px] md:h-[2px] bg-gray-300" />
       </div>
   );
};

export default Booking;
