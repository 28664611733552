import React, { useEffect, useState } from 'react';
import PortfolioItem from "../PortfolioItem";

const RealEstate = () => {
    const [images, setImages] = useState([]);
    const subheading = "This section offers a comprehensive showcase of properties, both residential and commercial, from around the world.";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://mannuelportraitsapi-production.up.railway.app/api/photo-list");
                const data = await response.json();
                const filteredImages = data
                    .filter(item => item.category && item.category.name === "Real Estate and Property")
                    .map(item => ({ id: item.id, imgSrc: item.image }));
                setImages(filteredImages);
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs only once on component mount

    return (
        <>
            <PortfolioItem title="Real Estate and Property" images={images} subheading={subheading}/>
        </>
    );
};

export default RealEstate;

