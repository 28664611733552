import React from 'react';
import PortfolioGallery from "./PortfolioGallery";

const PortfolioItem = ({ title, images, subheading }) => {
   return (
       <div>
          <div className="pb-16 text-center">
             <h1 className="mt-7 text-4xl md:text-5xl font-light font-brandonGrotesque">{title}</h1>
             <p className="pt-7 font-brandonGrotesque text-lg font-light font-brandonGrotesque px-4 md:px-36">{subheading}</p>
          </div>
          <PortfolioGallery images={images} title={title} />
       </div>
   );
};

export default PortfolioItem;
