import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black; /* Set the border color to black */
`;


const BlogArticle = () => {
  const { id } = useParams(); // Get the 'id' parameter from the URL
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const apiUrl = `https://mannuelportraits.up.railway.app/api/article-detail/${id}/`; // Use the 'id' parameter in the URL

    axios.get(apiUrl)
      .then((response) => {
        setArticle(response.data);
        console.log(response.data)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [id]); // Add 'id' to the dependency array

  return (
    <div key={article.id} className="container mx-auto p-2 max-w-screen-lg">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.title}/>
        <meta name="keywords" content="Best Photographer in Kenya"/>
        <link rel="canonical" href={canonicalURL + "/blog/"+ article.id}/>
      </Helmet>
      {loading &&
        (<div className="flex justify-center items-center h-screen">
          <ClipLoader color="black" css={override} size={100} />
        </div>)}
      {!loading && (
        <div className="mx-auto bg-white shadow-md p-8 rounded-lg">
          <div className="mb-4">
            <img 
              src={article.thumbnail} 
              alt="Thumbnail" 
              className="mx-auto w-full h-72 object-cover rounded-lg"
            />
          </div>
          <h1 className="text-3xl font-bold mb-4 font-vollkorn">{article.title}</h1>
          <pre className="text-gray-700 w-full font-worksans" style={{ whiteSpace: 'pre-wrap' }}>
            {article.body}
          </pre>
        </div>
      )}
    </div>
  );
  
}

export default BlogArticle;
