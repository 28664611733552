import React from 'react';
import GalleryImage from "./GalleryImage";

import footerOne from "../../img/footer_gallery/footer-1.jpg"
import footerTwo from "../../img/footer_gallery/footer-2.jpg"
import footerThree from "../../img/footer_gallery/footer-3.jpg"
import footerFour from "../../img/footer_gallery/footer-4.jpg"
import footerFive from "../../img/footer_gallery/footer-5.jpg"
import footerSix from "../../img/footer_gallery/footer-6.jpg"



const FooterGallery = () => {
   const images = [
      { src: footerOne },
      { src: footerTwo },
      { src: footerThree },
      { src: footerFour },
      { src: footerFive },
      { src: footerSix },
   ];

   return (
       <div className="grid grid-cols-2 gap-6 md:grid-cols-6 md:gap-4">
          {images.map((image, index) => (
              <GalleryImage key={index} src={image.src}/>
          ))}
       </div>
   );
};

export default FooterGallery;
