import React from 'react';
import Button from "../../utils/Button";

const FormSuccess = () => {
   return (
       <div className="px-16">
          <div className="my-32">
             <h1 className="text-4xl text-center font-bold font-vollkorn">Thank you for your booking!</h1>
             <p className="pt-4 text-xl text-center">We will get back to you as soon as possible.</p>
             <div className="text-center pt-10">
                <a href="/portfolio">
                   <Button text={"View my Work"} classes={""} />
                </a>
             </div>
          </div>
       </div>
   );
};

export default FormSuccess;
