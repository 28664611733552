import React from 'react';
import PortfolioGeneral from "./portfolio-general/PortfolioGeneral";
import HeadingSubheading from "../../utils/HeadingSubheading";
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async";

const Portfolio = () => {

   return (
       <div className="w-full px-4 md:px-16">
           <Helmet>
               <title>Gallery - MannuelPortraits</title>
               <meta name="description" content="Best photographer's gallery in Kenya"/>
               <meta name="keywords" content="Best Photographer in Kenya"/>
               <link rel="canonical" href={canonicalURL + "/portfolio"}/>
           </Helmet>
          <HeadingSubheading pageHeading="Gallery"/>
          <div className="w-full h-full">
            <PortfolioGeneral/>
          </div>
       </div>
   );
};

export default Portfolio;
