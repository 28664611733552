import React from 'react';
import LinkButton from "./LinkButton";


const LinkButtonOthers = ({ href, text }) => {
    return (
        <LinkButton
            href={href}
            text={text}
            classes="hover:bg-opacity-20 hover:bg-light-harrows-gate hover:text-gray-900 hover:border-black hover:border-[2px] border-gray-700"
        />
    );
};

export default LinkButtonOthers;
