import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black; /* Set the border color to black */
`;

export default function BlogHero() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(1); // Start with the second latest article

    useEffect(() => {
        const apiUrl = 'https://mannuelportraits.up.railway.app/api/article-list/';

        axios.get(apiUrl)
            .then((response) => {
                // Slice the last 3 items from the response data
                const lastThreePosts = response.data.slice(-3);
                setPosts(lastThreePosts);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <a href='/blog' className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blogs</h2>
                        <p className="mt-2 text-lg leading-8 text-gray-600">
                            Explore Our Blogs
                        </p>
                    </a>
                    <div className="mx-auto mt-16 flex justify-center items-center">
                        <ClipLoader color="black" css={override} size={30} />
                    </div>
                </div>
            </div>
        );
    }

    const [thirdLatest, secondLatest, latest] = posts;

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    };

    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <a href='/blog' className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blogs</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Explore Our Blogs
                    </p>
                </a>
                <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {/* Large Screen Layout */}
                    {thirdLatest && (
                        <a href={`/blog/${thirdLatest.id}`} className="hidden lg:block lg:col-span-1">
                            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-48 lg:pt-72">
                                <img src={thirdLatest.thumbnail} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                    <time dateTime={thirdLatest.datetime} className="mr-8">
                                        {thirdLatest.date} ago
                                    </time>
                                    <div className="-ml-4 flex items-center gap-x-4">
                                        <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="flex gap-x-2.5">
                                            <p className="flex-none">{thirdLatest.author.username}</p>
                                            {thirdLatest.author.name}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    <a href={`/blog/${thirdLatest.id}`}>
                                        <span className="absolute inset-0" />
                                        {thirdLatest.title}
                                    </a>
                                </h3>
                            </article>
                        </a>
                    )}
                    {secondLatest && (
                        <a href={`/blog/${secondLatest.id}`} className="hidden lg:block lg:col-span-1">
                            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-72 lg:pt-96">
                                <img src={secondLatest.thumbnail} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                    <time dateTime={secondLatest.datetime} className="mr-8">
                                        {secondLatest.date} ago
                                    </time>
                                    <div className="-ml-4 flex items-center gap-x-4">
                                        <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="flex gap-x-2.5">
                                            <p className="flex-none">{secondLatest.author.username}</p>
                                            {secondLatest.author.name}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    <a href={`/blog/${secondLatest.id}`}>
                                        <span className="absolute inset-0" />
                                        {secondLatest.title}
                                    </a>
                                </h3>
                            </article>
                        </a>
                    )}
                    {latest && (
                        <a href={`/blog/${latest.id}`} className="hidden lg:block lg:col-span-1">
                            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-48 lg:pt-72">
                                <img src={latest.thumbnail} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                    <time dateTime={latest.datetime} className="mr-8">
                                        {latest.date} ago
                                    </time>
                                    <div className="-ml-4 flex items-center gap-x-4">
                                        <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="flex gap-x-2.5">
                                            <p className="flex-none">{latest.author.username}</p>
                                            {latest.author.name}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                    <a href={`/blog/${latest.id}`}>
                                        <span className="absolute inset-0" />
                                        {latest.title}
                                    </a>
                                </h3>
                            </article>
                        </a>
                    )}
                    {/* Mobile Layout */}
                    <div className="lg:hidden">
                        <div className="relative flex items-center justify-center">
                            <button onClick={handlePrevClick} className="absolute left-0 z-10 p-2 text-white bg-gray-900 rounded-full">
                                <FaArrowLeft />
                            </button>
                            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-72 mx-auto w-full">
                                {posts[currentIndex] && (
                                    <>
                                        <img src={posts[currentIndex].thumbnail} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                        <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                                        <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                            <time dateTime={posts[currentIndex].datetime} className="mr-8">
                                                {posts[currentIndex].date} ago
                                            </time>
                                            <div className="-ml-4 flex items-center gap-x-4">
                                                <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                                    <circle cx={1} cy={1} r={1} />
                                                </svg>
                                                <div className="flex gap-x-2.5">
                                                    <p className="flex-none">{posts[currentIndex].author.username}</p>
                                                    {posts[currentIndex].author.name}
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                            <a href={`/blog/${posts[currentIndex].id}`}>
                                                <span className="absolute inset-0" />
                                                {posts[currentIndex].title}
                                            </a>
                                        </h3>
                                    </>
                                )}
                            </article>
                            <button onClick={handleNextClick} className="absolute right-0 z-10 p-2 text-white bg-gray-900 rounded-full">
                                <FaArrowRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
