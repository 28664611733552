import React from 'react';

const GalleryImage = ({ src }) => {
   const imageStyles = {
      backgroundImage: `url(${src})`
   };

   return (
       <div
           className="transition-opacity ease-in-out duration-700 hover:opacity-50 bg-center opacity-100 bg-no-repeat bg-cover w-full md:w-[180px] h-[200px]"
           style={imageStyles}>
       </div>
   );
};

export default GalleryImage;
