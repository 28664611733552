import React, { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

import logo from "../../img/logo/logo.svg";
import { navigationLinks } from "../../utils";


function classNames(...classes) {
   return classes.filter(Boolean).join(' ');
}

export default function Navbar({ textColor }) {
   const [hambugerOn, setHambugerOn] = useState(false);
   const changeHambugerTextColor = () => hambugerOn ? 'text-gray-800' : textColor;

   const removeScroll = () => {
      console.log("REMOVE SCROLL");
      setHambugerOn(true);
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('.homepagebg').classList.add('bg-sect');

   };

   const restoreScroll = () => {
      console.log("RESTORE SCROLL");
      setHambugerOn(false);
      document.querySelector('body').style.overflow = 'visible';
      document.querySelector('.homepagebg').classList.remove('bg-sect');
   };

   // TODO set navbar to hamburger menu on medium screens up (768px)
   return (
       <Disclosure as="nav" className="">
          {({ open }) => (
              <>
                 <div className="w-full homepagebg transition-colors duration-300 ease-in">
                    <div className="pr-2 pl-4 md:px-16 flex w-full h-28 items-center justify-between">
                       <div className={`font-vollkorn hover:cursor-pointer ${changeHambugerTextColor()}`}>
                          <div className="flex items-center">
                             <img src={logo} alt="logo" className={`h-16 w-16 ${textColor === 'text-white' ? 'turn-white' : ''}`}/>
                             <Link to="/" className="pl-3 font-haigrastSerif cursor-pointer text-lg md:text-3xl">MannuelPortraits</Link>
                          </div>


                       </div>

                       {/* navlinks for large screens */}
                       <div
                           className="flex flex-1 items-center justify-end sm:items-stretch">
                          <div className="hidden sm:ml-6 sm:block">
                             <div className="flex space-x-10">
                                {navigationLinks.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={classNames(`${changeHambugerTextColor()}`,
                                            'rounded-md py-2 font-bold',
                                            'font-cprime uppercase text-sm font-light tracking-widest hover:opacity-70 transition-opacity duration-100 ease-in'
                                        )}
                                        // aria-current={item.current ? 'page' : undefined}
                                    >
                                       {item.name}
                                    </a>
                                ))}
                             </div>
                          </div>
                       </div>

                       {/* Hamburger icons*/}
                       <div className="sm:hidden">
                          {/* Mobile menu button*/}
                          <Disclosure.Button
                              className={`items-center justify-center p-2 ${changeHambugerTextColor()}`}>
                             {open ? (
                                 <XMarkIcon onClick={restoreScroll} className="block h-8 w-8" aria-hidden="true"/>
                             ) : (
                                 <Bars3Icon onClick={removeScroll} className="block h-8 w-8" aria-hidden="true"/>
                             )}
                          </Disclosure.Button>
                       </div>
                    </div>
                 </div>

                 {/* Navlinks When hambuger opens*/}
                 <Transition
                     enter="transition duration-300 ease-out"
                     enterFrom="transform scale-95 opacity-0"
                     enterTo="transform scale-100 opacity-100"
                     leave="transition duration-200 ease-out"
                     leaveFrom="transform scale-100 opacity-100"
                     leaveTo="transform scale-95 opacity-0"
                 >
                    <Disclosure.Panel className="bg-sect font-vollkorn flex-col h-screen sm:hidden">
                       {({ open }) => (
                           <div className="space-y-1 px-5 pt-16 pb-3">
                              {navigationLinks.map((item) => (
                                  <>
                                     {/* TODO change 'a' tags to 'Link'*/}
                                     <div onClick={() => console.log("")}>
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames('hover:text-gray-500', `${changeHambugerTextColor()}`,
                                                'block rounded-md px-3 py-3 text-xl font-medium'
                                            )}
                                        >
                                           {item.name}
                                        </Disclosure.Button>
                                     </div>
                                     <hr className="h-[2px] bg-gray-400"/>
                                  </>
                              ))}
                           </div>
                       )}
                    </Disclosure.Panel>
                 </Transition>
              </>
          )}
       </Disclosure>
   );
}
