import { CheckCircleIcon } from '@heroicons/react/20/solid'
import classicImg from '../../../img/pricing/classic.jpg'
import bronzeImg from '../../../img/pricing/bronze.jpg'
import goldImg from '../../../img/pricing/gold.jpg'
import canonicalURL from "../../utils/canonicalURL";
import {Helmet} from "react-helmet-async";
import React from "react";


const tiers = [
  {
    name: 'Classic',
    id: 'tier-basic',
    href: '#',
    price: "80,000",
    features: [
        '1 photographer',
        '1 filmmaker',
        'Reception shoot till late',
        '1 Wedding highlight',
        'up to 30 mins wedding video',
        'up to 20 days processing time'
    ],
    imageSrc: classicImg, // Add image source here
  },
  {
    name: 'Bronze',
    id: 'tier-essential',
    href: '#',
    price: "140,000",
    features: [
      '2 photographers',
      '1 filmmaker',
      'Reception shoot till late',
      '1 Wedding highlight',
      'up to 45 mins wedding video',
      'up to 14 days processing time'
    ],
    imageSrc: bronzeImg, // Add image source here
  },
  {
    name: 'Gold',
    id: 'tier-growth',
    href: '#',
    price: "200,000",
    features: [
      '2 photographers',
      '2 filmmakers',
      'drone pilot',
      'Reception shoot till late',
      '2 Wedding highlights',
      'up to 60 mins wedding video',
      'up to 14 days processing time'
    ],
    imageSrc: goldImg, // Add image source here
  },
]

export default function PackagePage() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <Helmet>
        <title>Pricing - MannuelPortraits</title>
        <meta name="description" content="Charges for the Best photography or videography in Kenya"/>
        <meta name="keywords" content="Best Photographer in Kenya"/>
        <link rel="canonical" href={canonicalURL + "/packages"}/>
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Choose the right plan for you
          </p>
        </div>

        <div className="mt-20 grid md:gap-10 gap-16 lg:grid-cols-3">
          {tiers.map((tier) => (
            <div key={tier.id} className="relative bg-white border border-gray-200 rounded-lg shadow-md pt-12 px-8">
              {/* Circular Image */}
              <div className="absolute inset-x-0 top-0 flex justify-center transform -translate-y-1/2">
                <img
                  className="h-28 w-28 rounded-full border-4 border-white object-cover"
                  src={tier.imageSrc}
                  alt=""
                />
              </div>

              {/* Card Content */}
              <h3 id={tier.id} className="mt-16 text-base font-semibold leading-7 text-gray-900 text-center">
                {tier.name}
              </h3>
              <p className="mt-6 flex items-baseline justify-center gap-x-1">
                <span className="text-3xl font-bold tracking-tight text-gray-900">Ksh. {tier.price}</span>
              </p>
              <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckCircleIcon className="h-6 w-5 flex-none text-vol" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>

            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
