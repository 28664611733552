import React, { useEffect, useState } from 'react';
import PortfolioItem from "../PortfolioItem";

const Corporate = () => {
   const [images, setImages] = useState([]);
   const subheading = "This section offers a comprehensive collection of professional photographs designed to showcase the corporate world. If you are looking to promote your brand, You are in the right place.";

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await fetch("https://mannuelportraitsapi-production.up.railway.app/api/photo-list");
            const data = await response.json();
            const filteredImages = data
                .filter(item => item.category && item.category.name === "Corporate")
                .map(item => ({ id: item.id, imgSrc: item.image }));
            setImages(filteredImages);
         } catch (error) {
            console.error("Error fetching images:", error);
         }
      };

      fetchData();
   }, []); // Empty dependency array ensures useEffect runs only once on component mount

   return (
       <>
          <PortfolioItem title="Corporate" images={images} subheading={subheading}/>
       </>
   );
};

export default Corporate;
