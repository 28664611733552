import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { FaTimes } from "react-icons/fa";

import './MainPlayer.css';

const playIconButton = () => {
    return (
        <div id="playButton"
            className="hover:opacity-50 transition ease-in-out duration-500 hover:cursor-pointer absolute inset-0 m-auto flex items-center justify-center bg-gray-200 h-16 w-16 md:h-20 md:w-20 rounded-full">
            <span className="font-light text-lg">Play</span>
        </div>
    );
};

const VideoPlayer = ({ videoId, previewImage, videoUrl }) => {
    const [currentExitLgBtn, setCurrentExitLgBtn] = React.useState([]);
    const [currentExitSmBtn, setCurrentExitSmBtn] = React.useState([]);
    const [playerReady, setPlayerReady] = React.useState(false);
    const ref = React.useRef(null);

    React.useEffect(() => {
        const currExitLgBtn = document.getElementById('exit_lg_button-' + videoId);
        const currExitSmBtn = document.getElementById('exit_sm_button-' + videoId);

        setCurrentExitLgBtn(currExitLgBtn);
        setCurrentExitSmBtn(currExitSmBtn)
    }, [playerReady, currentExitSmBtn, currentExitLgBtn]);

    const handleShowPreview = () => {
        currentExitLgBtn.classList.remove('hoverOutsidePlayer')
        currentExitLgBtn.classList.remove('show');
        currentExitLgBtn.classList.add('hide');

        currentExitSmBtn.classList.remove('show');
        currentExitSmBtn.classList.add('hide');

        ref.current.showPreview();
    };

    const handleClickPreview = () => {
        currentExitLgBtn.classList.add('hoverOutsidePlayer');
        currentExitLgBtn.classList.remove('hide');
        currentExitLgBtn.classList.add('show');

        currentExitSmBtn.classList.remove('hide');
        currentExitSmBtn.classList.add('show');
    };

    return (
        <div className="w-full h-0 pb-[56.25%] relative">
            <div id="player-container" className="absolute top-0 left-0 w-full h-full bg-black hover:cursor-pointer">
                {/* For Large Screens */}
                <div id={`exit_lg_button-${videoId}`} className="exit-button-desktop hidden md:block hover:cursor-pointer p-4 bg-gray-200 h-20 w-20 rounded-full absolute top-4 right-4 z-10"
                    onClick={handleShowPreview}
                >
                    <span className="font-light text-lg">Exit</span>
                </div>

                {/* For Mobile */}
                <div id={`exit_sm_button-${videoId}`} className="exit-button-mobile md:hidden absolute top-4 right-4 hover:cursor-pointer hover:opacity-100 p-3 bg-gray-200 h-10 w-10 rounded-full z-10"
                    onClick={handleShowPreview}
                >
                    <span className="font-light text-lg">
                        <FaTimes size="1.5rem" className="faq-icon" />
                    </span>
                </div>
                <ReactPlayer
                    playsinline={true}
                    onPlay={() => { currentExitSmBtn.classList.remove('show'); currentExitSmBtn.classList.add('hide'); }}
                    onPause={() => { currentExitSmBtn.classList.remove('hide'); currentExitSmBtn.classList.add('show'); }}
                    onClickPreview={handleClickPreview}
                    onReady={() => {
                        console.log('onReady');
                        setPlayerReady(true);
                    }}
                    ref={ref}
                    controls
                    playIcon={playIconButton()}
                    light={<img src={previewImage}
                        className="w-full h-full object-cover" alt="Thumbnail" />}
                    url={videoUrl}
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    );
};

export default VideoPlayer;
