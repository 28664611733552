import React from 'react';

const LinkButton = ({ href, text, classes }) => {
   return (
       <a
           href={href}
           className={`bg-transparent font-vollkorn text-lg text-gray-600 py-[0.6rem] px-5 border transition-colors duration-200 ${classes}`}>
          {text}
       </a>
   );
};

export default LinkButton;
