import React, { useEffect, useState } from 'react';
import PortfolioItem from "../PortfolioItem";

const Weddings = () => {
   const [images, setImages] = useState([]);
   const subheading = 'This section showcases a stunning collection of photographs that capture the beauty and joy of weddings. If you are looking for a photographer to capture your special day, you are in the right place.';

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await fetch("https://mannuelportraitsapi-production.up.railway.app/api/photo-list");
            const data = await response.json();
            const filteredImages = data
                .filter(item => item.category && item.category.name === "Weddings")
                .map(item => ({ id: item.id, imgSrc: item.image }));
            setImages(filteredImages);
         } catch (error) {
            console.error("Error fetching images:", error);
         }
      };

      fetchData();
   }, []); // Empty dependency array ensures useEffect runs only once on component mount

   return (
       <>
          <PortfolioItem title="Weddings" images={images} subheading={subheading}/>
       </>
   );
};

export default Weddings;
