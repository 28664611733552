import React, { useEffect, useState } from 'react';
import Carousel from "../../utils/carousel.component";
import "../../../App.css";
function LandingCarousel() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      try {
        const response = await fetch('https://mannuelportraits.up.railway.app/api/carousel-images');
        const data = await response.json();
        const imageUrls = data.map(item => item.image);
        setSlides(imageUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    fetchImages();
  }, []);

  return (
    <div className="">
      <Carousel slides={slides} />
    </div>
  );
}

export default LandingCarousel;
