import { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function Carousel({ slides }) {
  const [current, setCurrent] = useState(0);

  const previousSlide = () => {
    if (current === 0) setCurrent(slides.length - 1);
    else setCurrent(current - 1);
  };

  const nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [current]);

  return (
    <div className="overflow-hidden relative w-full h-full">
      <div
        className="flex transition ease-out duration-500"
        style={{
          width: `${slides.length * 100}%`,
          transform: `translateX(-${current * (100 / slides.length)}%)`,
        }}
      >
        {slides.map((s, i) => {
          return (
            <div
              key={i}
              className="w-full h-full"
              style={{ flex: `0 0 ${100 / slides.length}%` }}
            >
              <img
                src={s}
                className="object-cover w-full h-full md:object-cover sm:object-contain"
                style={{ maxHeight: '100vh' }}
                loading="lazy"
              />
            </div>
          );
        })}
      </div>

      <div className="absolute top-0 h-full w-full flex justify-between items-center text-white px-10 text-3xl">
        <button onClick={previousSlide}>
          <FaArrowLeft />
        </button>
        <button onClick={nextSlide}>
          <FaArrowRight />
        </button>
      </div>

      <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
        {slides.map((s, i) => {
          return (
            <img
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`md:w-24 md:h-12 w-16 h-8 object-cover rounded-md cursor-pointer ${
                i === current ? "opacity-50" : "opacity-100"
              }`}
              src={s}
            />
          );
        })}
      </div>
    </div>
  );
}
