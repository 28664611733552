import React, { useEffect, useState } from 'react';
import './portfoliogallery.css';
import CloseIcon from '@mui/icons-material/Close';
import AOS from 'aos';
import 'aos/dist/aos.css';

const PortfolioGallery = ({ images, title }) => {
   useEffect(() => {
      AOS.init({ duration: 200 });
   }, []);

   const [model, setModel] = useState(false);
   const [tempImgSrc, setTempImgSrc] = useState('');
   const getImg = (imgSrc) => {
      setTempImgSrc(imgSrc);
      setModel(true);
   };

   return (
       <div className="px-5 md:px-16">
          <div className={model ? 'model open' : 'model'}>
             <img src={tempImgSrc} alt="img"/>
             <CloseIcon onClick={() => setModel(false)}/>
          </div>
          <div className="gallery">
             {images.map((item, index) => {
                return (
                    <div data-aos-duration="500" data-aos="zoom-in" data-aos="zoom-in"
                         className="md:px-2 mb-6 md:mb-8 pics transition-opacity duration-200" key={index}
                         onClick={() => getImg(item.imgSrc)}>
                       <img className="hover:cursor-zoom-in" src={item.imgSrc} style={{ width: '100%' }} alt={title} loading="lazy"/>
                    </div>
                );
             })}
          </div>
       </div>
   );
};

export default PortfolioGallery;
